import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  document.querySelectorAll('[data-animation="FadeIn"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=30%",
          end: "+=100",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 0.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="FadeInDark"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top top+=400",
          end: "+=30",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 0.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });
  document.querySelectorAll('[data-animation="FadeInDark2"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top top+=500",
          end: "+=40",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 0.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });
  document.querySelectorAll('[data-animation="FadeIn(1)"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=30%",
          end: "+=200",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 1.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="FadeIn(2)"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=15%",
          end: "+=225",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 1.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="FadeIn(3)"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=20%",
          end: "+=250",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 1.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="FadeIn(4)"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=25%",
          end: "+=275",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 1.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="FadeIn(5)"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=30%",
          end: "+=300",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          scale: 1.9,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="fadeUp"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=30%",
          end: "+=250",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          y: 80,
          scale: 0.9,
        },
        {
          opacity: 1,
          y: 0,
          scale: 1,
        }
      );
  });

  document.querySelectorAll('[data-animation="fadeLeft"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=30%",
          end: "+=250",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          x: -50,
        },
        {
          opacity: 1,
          x: 0,
        }
      );
  });

  document.querySelectorAll('[data-animation="fadeRight"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=30%",
          end: "+=250",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          opacity: 0,
          x: 80,
        },
        {
          opacity: 1,
          x: 0,
        }
      );
  });

  document.querySelectorAll('[data-animation="colourFade"]').forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top center+=0%",
          end: "+=200",
          scrub: 1,
        },
      })
      .fromTo(
        el,
        {
          backgroundColor: gsap.getProperty("html", "--dark"),
          opacity: 1,
          color: gsap.getProperty("html", "--light"),
        },
        {
          backgroundColor: gsap.getProperty("html", "--light"),
          color: gsap.getProperty("html", "--dark"),
          opacity: 1,
        }
      );
  });

  // const recolor = (element) => {
  //   const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(10);

  //   circle.setAttribute("fill", randomColor);

  //   setTimeout(() => recolor(element), 500);
  // };

  // recolor(document.querySelector("#circle"));

  return () => {};
}
